import { matrix4ToAlignmentTransform } from "@/alignment-tool/utils/alignment-transform";
import { Mode, ModeTransitionProps } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import {
  resetSheetToCloudAlignment,
  setSheetElevationForAlignment,
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useEffect } from "react";
import { useSheetSelectedForAlignment } from "../mode-data-context";
import { SheetToCloudAlignmentModeOverlay } from "./sheet-to-cloud-alignment-mode-overlay";
import { SheetToCloudAlignmentModeScene } from "./sheet-to-cloud-alignment-mode-scene";

export const sheetToCloudAlignmentMode: Mode = {
  name: "sheetToCloudAlignment",
  ModeScene: SheetToCloudAlignmentModeScene,
  ModeOverlay: SheetToCloudAlignmentModeOverlay,
  ModeTransition: SheetToCloudAlignmentModeTransition,
  exclusive: {
    title: "Align sheet to Point Cloud",
    onBack({ dispatch }) {
      // if user canceled alignment by pressing back we need to reset
      // temporary data to prevent reusing it in the next session of alignment
      dispatch(resetSheetToCloudAlignment());

      dispatch(changeMode("start"));

      return Promise.resolve();
    },
  },
};

function SheetToCloudAlignmentModeTransition({
  onCompleted,
}: ModeTransitionProps): null {
  const sheet = useSheetSelectedForAlignment("sheetToCloud");

  const sheetWorldMatrix = useAppSelector(selectIElementWorldMatrix4(sheet.id));

  const dispatch = useAppDispatch();

  useEffect(() => {
    // make sure to start with the first step (set elevation)
    dispatch(
      setStepForSheetToCloudAlignment(SheetToCloudAlignmentStep.setElevation),
    );

    // initialize sheet elevation in store with current sheet position in world
    dispatch(
      setSheetElevationForAlignment(
        matrix4ToAlignmentTransform(sheetWorldMatrix).position[1],
      ),
    );

    onCompleted();
  }, [dispatch, onCompleted, sheetWorldMatrix]);

  return null;
}
