import { NEAR_PLANE_DISTANCE } from "@/alignment-tool/alignment-steps/alignment";
import { useActiveCameras } from "@/components/common/view-runtime-context";
import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { useObjectBoundingBox } from "@/hooks/use-object-bounding-box";
import { useObjectVisibility } from "@/hooks/use-object-visibility";
import { useCached3DObject } from "@/object-cache";
import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  DesaturatePass,
  EffectPipeline,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  UniformLights,
  useNonExhaustiveEffect,
  View,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElementAreaSection,
  IElementImg360,
  IElementModel3dStream,
  IElementSectionDataSession,
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementModel3dStream,
  isIElementPointCloudStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import { reproportionCamera } from "@faro-lotv/lotv";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { OrthographicCamera, PerspectiveCamera, Vector2, Vector3 } from "three";
import {
  useNewOrthographicCamera,
  useNewPerspectiveCamera,
} from "../alignment-modes-commons/align-to-cad-utils";
import { FLOOR_OVERLAP } from "../alignment-modes-commons/model-elevation-scene";
import { useOverlayElements } from "../overlay-elements-context";

/** @returns Rendered element for Alignment Wizard */
export function AlignWizardModeScene(): JSX.Element | null {
  const { firstScreen, secondScreen } = useOverlayElements();

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  return (
    <>
      {firstScreen && elementToAlignId && (
        <AlignmentWizardView
          elementId={elementToAlignId}
          screen={firstScreen}
        />
      )}

      {secondScreen && referenceElementId && (
        <AlignmentWizardView
          elementId={referenceElementId}
          screen={secondScreen}
        />
      )}
    </>
  );
}

type AlignmentWizardViewProps = {
  /** element to display in view*/
  elementId: string;

  /** screen to render view */
  screen: HTMLDivElement;
};

/** @returns Rendered one of views of Alignment Wizard */
function AlignmentWizardView({
  elementId,
  screen,
}: AlignmentWizardViewProps): JSX.Element | null {
  const background = useThree((s) => s.scene.background);

  // both left and right cameras could be perspective for cloud/cad or orthographic for sheet
  const orthographicCamera = useNewOrthographicCamera();
  const perspectiveCamera = useNewPerspectiveCamera();

  const cameras = useMemo(() => {
    return [orthographicCamera, perspectiveCamera];
  }, [orthographicCamera, perspectiveCamera]);
  useActiveCameras(cameras);

  const element = useAppSelector(selectIElement(elementId));
  assert(element, "invalid element cannot be rendered");

  const isArea = isIElementAreaSection(element);
  const aspectRatio = screen.clientWidth / screen.clientHeight;

  return (
    <View
      camera={isArea ? orthographicCamera : perspectiveCamera}
      trackingElement={screen}
      background={background}
      hasSeparateScene
    >
      {isArea && (
        <SheetSceneView
          camera={orthographicCamera}
          viewAspectRatio={aspectRatio}
          area={element}
        />
      )}
      {isIElementSectionDataSession(element) && (
        <CloudSceneView camera={perspectiveCamera} cloud={element} />
      )}

      {isIElementModel3dStream(element) && (
        <CadSceneView camera={perspectiveCamera} cad={element} />
      )}
    </View>
  );
}

type SheetSceneViewProps = {
  /** The area to align */
  area: IElementAreaSection;

  /** camera for view */
  camera: OrthographicCamera;

  /** view aspect ration*/
  viewAspectRatio: number;
};

/** @returns Rendered sheet in one of views of Alignment Wizard */
function SheetSceneView({
  camera,
  viewAspectRatio,
  area,
}: SheetSceneViewProps): JSX.Element | null {
  const sheetElement = useAppSelector(
    selectChildDepthFirst(area, isIElementGenericImgSheet),
  );
  if (!sheetElement) {
    throw new Error("Area does not have a sheet");
  }

  const cachedSheet = useCached3DObject(sheetElement);

  const sheetCenteringData = useCenterCameraOnPlaceholders({
    sheetElement,
    placeholders: new Array<IElementImg360>(),
    viewAspectRatio,
  });

  useNonExhaustiveEffect(() => {
    centerOrthoCamera(camera, sheetCenteringData);
  }, []);

  return (
    <>
      <SheetRenderer sheet={cachedSheet} />
      <EffectPipeline>
        <FilteredRenderPass filter={(o) => o.name === sheetElement.id} />
        <DesaturatePass />
        <FilteredRenderPass
          filter={(o) => o.name !== sheetElement.id}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipeline>
      <SheetModeControls camera={camera} />
    </>
  );
}

type CloudSceneViewProps = {
  /** The cloud to align */
  cloud: IElementSectionDataSession;

  /** camera for view */
  camera: PerspectiveCamera;
};

/** @returns Rendered cloud in one of views of Alignment Wizard */
function CloudSceneView({
  camera,
  cloud,
}: CloudSceneViewProps): JSX.Element | null {
  const cloudElement = useAppSelector(
    selectChildDepthFirst(cloud, isIElementPointCloudStream),
  );
  if (!cloudElement) {
    throw new Error("Section DataSession does not have a point cloud stream");
  }

  const pointCloud = useCached3DObject(cloudElement);

  const cloudBox = useObjectBoundingBox(pointCloud, pointCloud.iElement.id);
  assert(cloudBox, "Cloud bounding box must exist");
  const { cloudCenter } = useMemo(() => {
    const bboxSize = cloudBox.getSize(new Vector3());
    const extents = new Vector2(
      bboxSize.x * FLOOR_OVERLAP,
      bboxSize.z * FLOOR_OVERLAP,
    );
    const cloudCenter = cloudBox.getCenter(new Vector3());
    return { extents, cloudCenter };
  }, [cloudBox]);

  const size = useThree((s) => s.size);

  useEffect(() => {
    const diagonal = cloudBox.getSize(new Vector3()).length();
    camera.position.set(
      cloudCenter.x - diagonal,
      cloudCenter.y + 0.1 * diagonal,
      cloudCenter.z - 0.5 * diagonal,
    );

    camera.updateMatrixWorld(true);
    const cameraFarFactor = 50;
    camera.far = diagonal * cameraFarFactor;
    camera.near = NEAR_PLANE_DISTANCE;
    reproportionCamera(camera, size.width / size.height);
  }, [cloudBox, camera, cloudCenter, size]);

  useObjectVisibility(pointCloud, true);

  return (
    <>
      <UniformLights />
      <PointCloudRenderer pointCloud={pointCloud} />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={pointCloud} />
        <FilteredRenderPass
          filter={(o) => !isObjPointCloudPoint(o)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>

      <ExplorationControls
        enabled={true}
        target={cloudCenter}
        disableInertia={true}
        hidePivot={true}
      />
    </>
  );
}

type CadSceneViewProps = {
  /** The cad to display in view */
  cad: IElementModel3dStream;

  /** camera for view */
  camera: PerspectiveCamera;
};

/** @returns Rendered cad in one of views of Alignment Wizard */
function CadSceneView({ camera, cad }: CadSceneViewProps): JSX.Element | null {
  const cadModel = useCached3DObject(cad);

  useObjectVisibility(cadModel, true);
  const size = useThree((s) => s.size);

  const cadBox = useObjectBoundingBox(cadModel, cadModel.iElement.id);

  const cadCenter = useMemo(() => {
    return cadBox ? cadBox.getCenter(new Vector3()) : undefined;
  }, [cadBox]);

  useEffect(() => {
    if (cadBox && cadCenter) {
      const diagonal = cadBox.getSize(new Vector3()).length();
      camera.position.set(
        cadCenter.x - diagonal,
        cadCenter.y + 0.1 * diagonal,
        cadCenter.z - 0.5 * diagonal,
      );

      camera.updateMatrixWorld(true);
      const cameraFarFactor = 50;
      camera.far = diagonal * cameraFarFactor;
      camera.near = NEAR_PLANE_DISTANCE;
      reproportionCamera(camera, size.width / size.height);
    }
  }, [cadBox, cadCenter, camera, size]);

  if (!cadBox || !cadCenter) return null;

  return (
    <>
      <UniformLights />
      <CadRenderer cadModel={cadModel} />
      <ExplorationControls
        enabled={true}
        target={cadCenter}
        disableInertia={true}
        hidePivot={true}
      />
    </>
  );
}
