import { ViewRuntimeContextData } from "@/components/common/view-runtime-context";
import {
  CameraParameters,
  decodeCameraParameters,
  encodeCameraParameters,
  getCameraParameters,
} from "@/components/r3f/utils/camera-parameters";
import {
  OrientedBoundingBox,
  decodeOrientedBoundingBox,
  encodeOrientedBoundingBox,
} from "@/components/r3f/utils/oriented-bounding-box";
import { AppDispatch, AppStore, RootState } from "@/store/store";
import { SceneFilter } from "@/types/scene-filter";
import { GUID, IElement } from "@faro-lotv/ielement-types";
import { WalkOrbitControls } from "@faro-lotv/lotv";
import { ProjectApi } from "@faro-lotv/project-api-client";
import { Camera } from "@react-three/fiber";
import { CubeTexture, PerspectiveCamera } from "three";

/** All available mode names */
export type ModeNames =
  | "start"
  | "sheet"
  | "overview"
  | "walk"
  | "split"
  | "clippingbox"
  | "pathAlignment"
  | "projectConversion"
  | "floorscale"
  | "createArea"
  | "sheetToCadAlignment"
  | "cloudToCadAlignment"
  | "alignWizard"
  | "sheetToCloudAlignment";

export type DefaultModeInitialState = {
  /** The parameters describing the camera configuration */
  camera?: CameraParameters;

  /** The active clipping box if available */
  clipping?: OrientedBoundingBox;
};

/** The initial state to restore a mode with scene filter from a deep link */
export type ModeWithSceneFilterInitialState = DefaultModeInitialState & {
  /** The active scene filter */
  scene: SceneFilter;
};

/** The initial state to restore a mode with scene filter and support for look at items from a deep link */
export type SceneFilterLookAtInitialState = ModeWithSceneFilterInitialState & {
  /** The iElement of interest from the deep link */
  lookAtId?: GUID;
};

export type ModeTransitionProps<InitialState = DefaultModeInitialState> =
  ModeSceneProps<InitialState> & {
    /** The previous mode name we're coming from */
    previousMode: ModeNames;

    /** The last environment map generated before the transition */
    previousEnvMap: CubeTexture;

    /** A callback to signal this transition is finished */
    onCompleted(): void;

    /** The default camera that by design is used in all transitions */
    defaultCamera: PerspectiveCamera;

    /** The mode custom camera if present, defaultCamera if not present */
    modeCamera: Camera;
  };

/** Function to restore the global camera to the state of the custom camera before an animation */
export type CameraRestorer = (
  custom: Camera,
  global: PerspectiveCamera,
) => void;

/** Object a mode can define to use a custom camera */
export type CustomCamera = {
  /** Function that will create the custom camera for the mode */
  camera(): Camera;

  /** Function to restore the GlobalCamera to the closest state possible to the custom camera before an animation */
  restoreGlobalCamera: CameraRestorer;
};

/** Context object passed to the back/apply callbacks of the exclusive modes */
type ExclusiveModeCallbackContext = {
  /** Current app store */
  store: AppStore;

  /** Dispatch function to update the store */
  dispatch: AppDispatch;

  /** Handle to the projectApi to mutate the project */
  projectApi: ProjectApi;
};

/** Properties specific of an ExclusiveMode */
export type ExclusiveModeProps = {
  /** This exclusive mode title */
  title: string;

  /**
   * Set to true to use the default app header and not the exclusive header
   *
   * @default false
   */
  useDefaultHeader?: boolean;

  /** Method called when the user want to exit an ExclusiveMode with the Back button */
  onBack?(context: ExclusiveModeCallbackContext): Promise<void>;

  /**
   * Method called when the user want to exit and exclusive mode with the Apply button
   *
   * If it's not defined no "Apply Changes" button will be available in the HeaderBar
   */
  onApply?(context: ExclusiveModeCallbackContext): Promise<void | string>;
};

export type ModeSceneProps<InitialState = DefaultModeInitialState> = {
  /** Optional initial state for the mode scene */
  initialState?: InitialState;
};

/** A type that defines an object with a string property for each property of the template type */
type EncodedState<State> = {
  [Property in keyof State]: string;
};

export type InitialStateProps<InitialState> = {
  /**
   * A function to extract the mode initial state from a generic deep link and validate it
   *
   * @param appState The current state of the app store
   * @param modeState The properties provided by the deep link
   * @returns The mode state or undefined if the passed parameters are not valid
   */
  parse(
    appState: RootState,
    modeState: Record<string, string | undefined>,
  ): InitialState | undefined;

  /**
   * A function to compute the encoded properties for deep link from the current mode state
   *
   * @param state The current state of the app store
   * @param viewContext The context containing the data from the view
   * @returns An object containing the properties for correctly encoding the state into a deep link
   */
  compute(
    state: RootState,
    viewContext: ViewRuntimeContextData,
  ): EncodedState<InitialState>;
};

/**
 * Base layout of a mode
 */
export type Mode<
  InitialState extends DefaultModeInitialState = DefaultModeInitialState,
> = {
  /** The mode name, used in url or logging */
  name: ModeNames;

  /** If defined expose the functions to parse and encode the mode state for deep links */
  initialState?: InitialStateProps<InitialState>;

  /** The R3F scene to render */
  ModeScene(props: ModeSceneProps<InitialState>): JSX.Element | null;

  /** The 2D overlay */
  ModeOverlay?(): JSX.Element | null;

  /** The drawer for the given mode, which is outside the overlay */
  ModeDrawer?(): JSX.Element | null;

  /** The quick-help drawer for the given mode */
  ModeQuickHelpDrawer?(): JSX.Element | null;

  /** A transition to animate from another mode to this mode */
  ModeTransition?(props: ModeTransitionProps<InitialState>): JSX.Element | null;

  /**
   * A mode can optionally specify a custom camera, but it also needs
   * to specify how to restore the global camera.
   *
   * If not specified, the default camera is used.
   */
  customCamera?: CustomCamera;

  /**
   * Check if this mode can render properly if the active element is the one passed as an argument
   * If not defined the mode is expected to support any active element and work properly
   *
   * @param activeElement that we want to render in this mode
   * @returns true if we can start this mode with the passed activeElement
   */
  canBeStartedWith?(activeElement: IElement, state: RootState): boolean;

  /** Define this property to make this mode Exclusive, so it gets full ui controls and a specific HederBar */
  exclusive?: ExclusiveModeProps;
};

export const DEFAULT_INITIAL_STATE: InitialStateProps<DefaultModeInitialState> =
  {
    parse(_, state) {
      const { camera, clipping } = state;
      return {
        camera: decodeCameraParameters(camera),
        clipping: decodeOrientedBoundingBox(clipping),
      };
    },
    compute(state, viewContext) {
      // If the current mode is using the WalkOrbitControls use the controls current target
      // in the mode state camera, so we can restore it later
      const controls = viewContext.controls?.[0];
      const target =
        controls instanceof WalkOrbitControls ? controls.target : undefined;
      return {
        camera: encodeCameraParameters(
          getCameraParameters(viewContext.cameras[0], target),
        ),
        clipping: state.clippingBox.clippingBox
          ? encodeOrientedBoundingBox(state.clippingBox.clippingBox)
          : undefined,
      };
    },
  };
