import { ElementIcon, useElementIcon } from "@/components/ui/icons";
import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  AnchorIcon,
  ArrowDownIcon,
  FaroIconButton,
  FaroText,
  FaroTooltip,
  neutral,
  selectIElement,
  ViewDiv,
} from "@faro-lotv/app-component-toolbox";
import { IElement } from "@faro-lotv/ielement-types";
import { Box, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ReferenceSelectorTool } from "./align-wizard-reference-selector-tool";

/**
 * @returns Renderer of split screen for Alignment Wizard
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side views of aligned and reference elements
 * - Facilitates selection of appropriate elements for alignment
 */
export function AlignWizardSplitScreen(): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const alignedElement = useAppSelector(selectIElement(elementToAlignId));

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const referenceElement = useAppSelector(selectIElement(referenceElementId));

  const [isSelectorCollapsed, setCollapsed] = useState(false);

  return (
    <Stack
      direction="row"
      justifyItems="stretch"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {alignedElement && (
          <AlignmentWizardElementLabel
            element={alignedElement}
            labelType={LabelType.alignedElement}
          />
        )}
      </ViewDiv>

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      >
        {referenceElement && isSelectorCollapsed && (
          <AlignmentWizardElementLabel
            element={referenceElement}
            labelType={LabelType.referenceElement}
          />
        )}

        {!isSelectorCollapsed && <ReferenceSelectorTool />}
      </ViewDiv>

      <Box
        component="div"
        sx={{
          position: "absolute",
          left: "50%",
          top: "80px",
        }}
      >
        <FaroIconButton
          sx={{
            position: "absolute",
            left: "-20px",
            backgroundColor: neutral[0],
            "&:hover": {
              background: `${neutral[100]}`,
            },
          }}
          onClick={() => {
            setCollapsed(!isSelectorCollapsed);
          }}
          title={
            isSelectorCollapsed
              ? "Expand selector tool"
              : "Collapse selector tool"
          }
        >
          <ArrowDownIcon
            sx={{
              transform: isSelectorCollapsed
                ? "rotate(-90deg)"
                : "rotate(90deg)",
              transition: "transform 0.1s linear",
            }}
          />
        </FaroIconButton>
      </Box>
    </Stack>
  );
}

/** All supported type of labels supported by AlignmentWizardElementLabel */
enum LabelType {
  /** Element which will be moved during alignment */
  alignedElement = 0,

  /** Element which will not move during alignment */
  referenceElement = 1,
}

type AlignmentWizardElementLabelProps = {
  /** Element for which label is rendered */
  element: IElement;

  /** Type of icon to render */
  labelType: LabelType;
};

/**
 * @returns control to select reference element in Alignment Wizard
 */
export function AlignmentWizardElementLabel({
  element,
  labelType,
}: AlignmentWizardElementLabelProps): JSX.Element {
  const elementIcon = useElementIcon(element);

  const stringLetters = 20;
  const referenceStringLetters = 25;
  const maxLabelTextLength =
    labelType === LabelType.referenceElement
      ? stringLetters
      : referenceStringLetters;

  let nameToDisplay = element.name;
  if (nameToDisplay && nameToDisplay.length > maxLabelTextLength) {
    nameToDisplay = `${element.name.substring(0, maxLabelTextLength - 3)}...`;
  }

  return (
    <Grid
      item
      container
      spacing={1}
      sx={{
        paddingX: "3px",
        borderRadius: "30px",
        border: "2px",
        borderColor: neutral[200],
        borderStyle: "solid",
        px: 1,
        position: "absolute",
        top: 90,
        height: "48px",
        left: "5%",
        width: "250px",
      }}
    >
      <Grid item xs={2}>
        <ElementIcon icon={elementIcon} />
      </Grid>
      <Grid item xs={labelType === LabelType.referenceElement ? 8 : 10}>
        <FaroTooltip title={element.name}>
          <FaroText variant="heading14" align="left">
            {nameToDisplay}
          </FaroText>
        </FaroTooltip>
      </Grid>
      <Grid item xs={labelType === LabelType.referenceElement ? 2 : 0}>
        {labelType === LabelType.referenceElement && (
          <FaroTooltip title="The reference element does not move during the alignment">
            <AnchorIcon htmlColor={neutral[400]} />
          </FaroTooltip>
        )}
      </Grid>
    </Grid>
  );
}
