import { useCached3DObject } from "@/object-cache";
import {
  selectClippingBoxEnabledForCadAlignment,
  selectSheetElevation,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import { setSheetElevation } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectAncestor,
  selectIElementWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementModel3dStream,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import { useCallback, useState } from "react";
import { Box3, PerspectiveCamera } from "three";
import { useCameraInCurrentScene } from "../alignment-modes-commons/align-to-cad-utils";
import { ModelElevationScene } from "../alignment-modes-commons/model-elevation-scene";
import { useSheetSelectedForAlignment } from "../mode-data-context";
export type SetHeightSceneProps = {
  /** The active cad model for alignment */
  activeCad: IElementModel3dStream;

  /** The bounding box of the cad model in world */
  cadBox: Box3;
};

/** @returns the scene for set the cad height */
export function SetHeightScene({
  activeCad,
  cadBox,
}: SetHeightSceneProps): JSX.Element {
  const cadModelObject = useCached3DObject(activeCad);
  const dispatch = useAppDispatch();

  const [camera] = useState<PerspectiveCamera>(() => new PerspectiveCamera());
  useCameraInCurrentScene(camera);

  const sheet = useSheetSelectedForAlignment("sheetToCad");
  const sectionArea = useAppSelector(
    selectAncestor(sheet, isIElementAreaSection),
  );

  const sheetWorldPosition = useAppSelector(
    selectIElementWorldPosition(sheet.id),
  );

  const isClippingBoxEnabled = useAppSelector(
    selectClippingBoxEnabledForCadAlignment,
  );

  const sheetElevation = useAppSelector(selectSheetElevation);

  const updateElevation = useCallback(
    (elevation: number) => {
      dispatch(setSheetElevation(elevation));
    },
    [dispatch],
  );

  return (
    <ModelElevationScene
      cadModelObject={cadModelObject}
      cadBox={cadBox}
      elevation={
        sheetElevation ??
        (sheet.pose ?? sectionArea?.pose ? sheetWorldPosition[1] : undefined)
      }
      updateElevation={updateElevation}
      isClippingBoxEnabled={isClippingBoxEnabled}
      camera={camera}
    />
  );
}
