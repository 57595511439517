import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import {
  blue,
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroText,
  fetchProjectIElements,
  selectIElement,
  selectIElementProjectApiLocalPose,
  selectIElementWorldTransform,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert, GUID } from "@faro-lotv/foundation";
import { isIElementBimModelSection } from "@faro-lotv/ielement-types";
import {
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
} from "@faro-lotv/service-wires";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { Matrix4 } from "three";

export type ChangeCadCsDialogProps = {
  /* true to display the dialog, false to keep it hidden */
  open: boolean;

  /** GUID of the Model being edited */
  idIElementModel3dStream: GUID;

  /** callback to be called when user close/cancel the dialog */
  onClose(): void;
};

/**
 * @returns component displaying a dialog asking user to change the coordinates system used for CAD
 */
export function ChangeCadCsDialog({
  open,
  idIElementModel3dStream,
  onClose,
}: ChangeCadCsDialogProps): JSX.Element | null {
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const [isConfirmDisabled, setConfirmButtonDisabled] = useState(true);
  const [transformInProgress, setTransformInProgress] = useState(false);

  // Which coordinate system to apply to Cad
  const userChoice = useRef<OrientationType>();

  // user's choice for whether we should we apply offset to model's Ref Point?
  const [offsetToRefPoint, setOffsetToRefPoint] = useState(false);

  // force the option to apply Ref Point offset (undefined mean listen to offsetToRefPoint; otherwise, ignore offsetToRefPoint)
  const [forceOffsetToRefPoint, setForceOffsetToRefPoint] = useState<boolean>();

  const enableOffsetToRefPoint = forceOffsetToRefPoint === undefined;

  // Called when user pressed Apply button in the  dialog
  const applyChangeCadCs = useCallback(async (): Promise<void> => {
    const model3DStreamElement = selectIElement(idIElementModel3dStream)(
      store.getState(),
    );
    assert(model3DStreamElement, "Invalid CAD stream");
    const bimModelSectionElement = selectIElement(
      model3DStreamElement.parentId,
    )(store.getState());
    assert(
      bimModelSectionElement &&
        isIElementBimModelSection(bimModelSectionElement),
      "Invalid 3D Model Element in applyTransformationToCad",
    );

    // Compute the new absolute transformation to apply to the CAD
    // Returns either a new transformation as a Matrix4, or undefined for identity transformation, or false if the user choice is invalid
    function getNewCadInWorld(): Matrix4 | undefined | false {
      switch (userChoice.current) {
        case OrientationType.useModelTrueNorth:
        case OrientationType.useModelNorth:
        case OrientationType.useModelView:
          // TODO: Implement the logic to compute the new transformation (will be done in a follow-up PR for https://faro01.atlassian.net/browse/CADBIM-732)
          throw new Error("Not implemented");
        case OrientationType.useDefault:
          return undefined;
        default:
          return false;
      }
    }
    const newCadInWorld = getNewCadInWorld();
    const cadParent = selectIElement(bimModelSectionElement.parentId)(
      store.getState(),
    );
    const cadParentWorldTransform = selectIElementWorldTransform(cadParent?.id)(
      store.getState(),
    );

    const mutationTransform = newCadInWorld
      ? selectIElementProjectApiLocalPose(
          bimModelSectionElement,
          new Matrix4()
            .fromArray(cadParentWorldTransform.worldMatrix)
            .invert()
            .multiply(newCadInWorld),
        )(store.getState())
      : undefined;

    setTransformInProgress(true);

    // apply the mutation updating the CAD transformation
    try {
      const mutations = [
        createMutationSetElementPosition(
          bimModelSectionElement.id,
          mutationTransform ? mutationTransform.pos : { x: 0, y: 0, z: 0 },
        ),
        createMutationSetElementRotation(
          bimModelSectionElement.id,
          mutationTransform
            ? mutationTransform.rot
            : { x: 0, y: 0, z: 0, w: 1 },
        ),
        createMutationSetElementScale(bimModelSectionElement.id, {
          x: 1,
          y: 1,
          z: 1,
        }),
      ];

      await projectApi.applyMutations(mutations);

      // Update the IElement tree
      await dispatch(
        fetchProjectIElements({
          fetcher: () =>
            projectApi.getAllIElements({
              // We only need to fetch the subtree starting from the modified element
              ancestorIds: [bimModelSectionElement.id],
            }),
        }),
      );

      openToast({
        title: "CAD transformation successfully applied",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new CAD transformation",
        error,
      });
    }

    setTransformInProgress(false);
  }, [
    handleErrorWithToast,
    projectApi,
    store,
    openToast,
    dispatch,
    idIElementModel3dStream,
  ]);

  return (
    <FaroDialog
      title="Change Model Coordinate System"
      open={open}
      onConfirm={applyChangeCadCs}
      onCancel={onClose}
      isConfirmDisabled={isConfirmDisabled}
      dark
      confirmText="Apply"
      showXButton
      showSpinner={transformInProgress}
    >
      <Stack gap={3}>
        <Typography>
          Select the model's coordinate system to be used in Sphere XG
        </Typography>
        <FaroRadioGroup
          onChange={(v) => {
            userChoice.current = stringToOrientationType(v.target.value);
            setConfirmButtonDisabled(false);
            switch (userChoice.current) {
              case OrientationType.useModelRefCs:
                setForceOffsetToRefPoint(true);
                break;
              case OrientationType.useDefault:
                setForceOffsetToRefPoint(false);
                break;
              case OrientationType.useModelTrueNorth:
              case OrientationType.useModelNorth:
              case OrientationType.useModelView:
              case OrientationType.useModelSystemCs:
                setForceOffsetToRefPoint(undefined);
                break;
            }
          }}
        >
          <FormControlLabel
            value="useDefault"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Reset to default orientation
              </FaroText>
            }
            aria-label="useDefault"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="useModelView"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Use model's view orientation
              </FaroText>
            }
            aria-label="useModelView"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="useModelNorth"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Use model's north orientation
              </FaroText>
            }
            aria-label="useModelNorth"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="useModelTrueNorth"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Use model's true north orientation
              </FaroText>
            }
            aria-label="useModelTrueNorth"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="useModelSystemCs"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Use model's system coordinates system
              </FaroText>
            }
            aria-label="useModelSystemCs"
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value="useModelRefCs"
            control={<FaroRadio dark />}
            label={
              <FaroText variant="bodyL" dark>
                Use model's Ref coordinates system
              </FaroText>
            }
            aria-label="useModelRefCs"
            sx={{ m: 0 }}
          />
        </FaroRadioGroup>
        <FormControlLabel
          label={
            <FaroText
              variant="bodyL"
              dark
              sx={{ m: 3 }}
              color={enableOffsetToRefPoint ? "white" : "gray"}
            >
              Offset to model's Ref Point
            </FaroText>
          }
          control={
            <Checkbox
              disabled={!enableOffsetToRefPoint}
              checked={forceOffsetToRefPoint ?? offsetToRefPoint}
              onChange={(ev) => setOffsetToRefPoint(ev.target.checked)}
              color="primary"
              sx={{
                p: 0,
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                  color: enableOffsetToRefPoint ? "white" : "gray",
                },
                "&.Mui-checked": {
                  "& .MuiSvgIcon-root": {
                    color: enableOffsetToRefPoint ? blue[300] : "gray",
                  },
                },
              }}
            />
          }
          sx={{ m: 0 }}
        />
      </Stack>
    </FaroDialog>
  );
}

// List of possible CS orientations.
// Take note that the CS store in the IElement is not the same one shown to the user
// (e.g. Z is up from user point of view, but Y is up in the IElement)
enum OrientationType {
  useDefault = "useDefault",
  useModelView = "useModelView",
  useModelNorth = "useModelNorth",
  useModelTrueNorth = "useModelTrueNorth",
  useModelSystemCs = "useModelSystemCs",
  useModelRefCs = "useModelRefCs",
}

// Convert the string associated with one of the radio button to the associated OrientationType
function stringToOrientationType(s: string): OrientationType | undefined {
  switch (s) {
    case OrientationType.useModelTrueNorth:
      return OrientationType.useModelTrueNorth;
    case OrientationType.useModelNorth:
      return OrientationType.useModelNorth;
    case OrientationType.useModelView:
      return OrientationType.useModelView;
    case OrientationType.useDefault:
      return OrientationType.useDefault;
    case OrientationType.useModelSystemCs:
      return OrientationType.useModelSystemCs;
    case OrientationType.useModelRefCs:
      return OrientationType.useModelRefCs;
  }
}
