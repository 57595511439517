import {
  selectSheetElevation,
  selectSheetToCadAlignmentActiveStepIndex,
  selectSheetToCadAlignmentStepIsLastStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  goToSheetToCadAlignmentNextStep,
  goToSheetToCadAlignmentPreviousStep,
  setClippingBoxEnabled,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { useCallback } from "react";
import { AlignToCadProgressBar } from "../alignment-modes-commons/align-to-cad-progress-bar";

interface CadAlignmentProgressBarProps {
  /** A callback that is called when the user wants to apply the alignment */
  apply(): void;
}

/**
 * @returns The bar that is shown in the top of a sheet-to-cad alignment mode
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function SheetToCadAlignmentProgressBar({
  apply,
}: CadAlignmentProgressBarProps): JSX.Element {
  const dispatch = useAppDispatch();
  const activeStepIndex = useAppSelector(
    selectSheetToCadAlignmentActiveStepIndex,
  );
  const isLastStep = useAppSelector(selectSheetToCadAlignmentStepIsLastStep);
  const sheetElevation = useAppSelector(selectSheetElevation);

  const goToAlignmentPreviousStep = useCallback(() => {
    dispatch(goToSheetToCadAlignmentPreviousStep());
  }, [dispatch]);

  const goToNextStepOrApplyAlignment = useCallback(() => {
    if (isLastStep) {
      apply();
    } else {
      dispatch(setClippingBoxEnabled(false));
      dispatch(goToSheetToCadAlignmentNextStep());
    }
  }, [apply, dispatch, isLastStep]);

  return (
    <AlignToCadProgressBar
      goToAlignmentNextStep={goToNextStepOrApplyAlignment}
      goToAlignmentPreviousStep={goToAlignmentPreviousStep}
      activeStepIndex={activeStepIndex}
      isLastStep={isLastStep}
      enableNextButton={activeStepIndex > 0 || sheetElevation !== undefined}
    />
  );
}
