import { useClippingPlanes } from "@/hooks/use-clipping-planes";
import { useCached3DObjectsIfReady } from "@/object-cache";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { computeCombinedPointCloudBoundingBox } from "@/registration-tools/utils/camera-views";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { ToolName } from "@/store/ui/ui-slice";
import { Canvas } from "@faro-lotv/app-component-toolbox";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { GUID, TypedEvent } from "@faro-lotv/foundation";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { RevisionScanEntity } from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { MutableRefObject, useCallback, useState } from "react";
import { Box3 } from "three";
import { DataPreparationToolsToolbar } from "../ui/data-praparation-tools-toolbar";
import { PerspectiveSwitch } from "../ui/perspective-switch";
import { Projection, ProjectionSwitch } from "../ui/projection-switch";
import { DataPreparationViewSettingsToolbar } from "../ui/view-settings-toolbar/data-preparation-view-settings-toolbar";
import { RegistrationConnections } from "./registration-connections";
import { RevisionScansControls } from "./revision-scans-controls";
import { RevisionScansRenderer } from "./revision-scans-renderer";
import { RevisionScansTools } from "./revision-scans-tools";

type RevisionScansProps = {
  /** The scans to render in the scene. */
  scanEntities: RevisionScanEntity[];

  /** The point cloud streams for the scan entities. undefined if they are still loading. */
  pointCloudStreams?: IElementGenericPointCloudStream[];

  /** The point cloud to highlight in a hover interaction */
  hoveredPointCloudId?: GUID;

  /** The point cloud to highlight in a selection interaction */
  selectedPointCloudId?: GUID;

  /** The report showing the quality of the registration. */
  registrationReport?: MultiRegistrationReport;

  /** A UI overlay to display on top of the canvas. */
  overlay?: React.ReactNode;
};

/** @returns A 3D scene displaying the scans included in the revision. */
export function RevisionScansScene({
  scanEntities,
  pointCloudStreams,
  hoveredPointCloudId,
  selectedPointCloudId,
  registrationReport,
  overlay,
}: RevisionScansProps): JSX.Element | null {
  const [portal, setPortal] = useState<MutableRefObject<HTMLElement>>();
  const pointCloudObjects = useCached3DObjectsIfReady(pointCloudStreams);

  const [centerCameraEvent] = useState(new TypedEvent<Perspective>());
  const [perspective, setPerspective] = useState(Perspective.topView);
  const [projection, setProjection] = useState(Projection.orthographic);

  const [combinedPcBoundingBox, setCombinedPcBoundingBox] = useState<Box3>();

  const { clippingPlanes, setClippingPlanes } = useClippingPlanes();

  const [activeTool, setActiveTool] = useState<ToolName>();
  const toggleTool = useCallback(
    (tool: ToolName) => {
      if (activeTool === tool) {
        setActiveTool(undefined);
      } else {
        setActiveTool(tool);
      }
    },
    [activeTool],
  );

  // Display a loading spinner if the point cloud streams are still loading
  if (
    !pointCloudStreams ||
    pointCloudObjects.length !== pointCloudStreams.length
  ) {
    return (
      <Box
        component="div"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ display: "flex", height: "100%" }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box
      ref={setPortal}
      component="div"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Canvas style={{ position: "absolute" }} orthographic>
        <RevisionScansRenderer
          scanEntities={scanEntities}
          pointCloudObjects={pointCloudObjects}
          hoveredPointCloudId={hoveredPointCloudId}
          selectedPointCloudId={selectedPointCloudId}
          onInitialPositioning={() => {
            centerCameraEvent.emit(perspective);

            // The world space bounding box can only be computed after the point clouds have been positioned
            setCombinedPcBoundingBox(
              computeCombinedPointCloudBoundingBox(
                pointCloudObjects,
                new Box3(),
              ),
            );
          }}
          clippingPlanes={clippingPlanes}
        />
        <RevisionScansControls
          centerCameraEvent={centerCameraEvent}
          pointCloudObjects={pointCloudObjects}
          projection={projection}
        />

        <RegistrationConnections
          scanEntities={scanEntities}
          registrationReport={registrationReport}
          portal={portal}
        />

        <RevisionScansTools
          activeTool={activeTool}
          combinedPcBoundingBox={combinedPcBoundingBox}
          onClippingPlanesChanged={setClippingPlanes}
        />
      </Canvas>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ p: 2, width: "100%", height: "100%" }}
      >
        <Stack justifyContent="end" alignItems="start" height="100%" gap={1}>
          {projection === Projection.orthographic && (
            <PerspectiveSwitch
              perspective={perspective}
              onPerspectiveChange={(value) => {
                setPerspective(value);
                centerCameraEvent.emit(value);
              }}
            />
          )}

          <ProjectionSwitch
            projection={projection}
            onProjectionChange={setProjection}
          />
        </Stack>

        <Stack justifyContent="center" gap={1}>
          <DataPreparationViewSettingsToolbar
            onRecenterView={() => centerCameraEvent.emit(perspective)}
          />

          <DataPreparationToolsToolbar
            activeTool={activeTool}
            onToggleTool={toggleTool}
          />
        </Stack>
      </Stack>

      {overlay}
    </Box>
  );
}
