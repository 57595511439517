import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroStep, FaroStepper, neutral } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useState } from "react";

interface AlignWizardProgressBarProps {
  /** A callback that is called when the user clicks "Next" button in the progressbar */
  goToNextStep(): void;
}

/** defines steps for Alignment Wizard progressbar */
const steps: FaroStep[] = [
  {
    key: "1",
    label: "Select reference",
  },
];

/**
 * @returns The progress bar that is shown at the top of an alignment Wizard mode
 *  Allows user to see where there are currently in the alignment process
 */
export function AlignWizardProgressBar({
  goToNextStep,
}: AlignWizardProgressBarProps): JSX.Element {
  const [activeStepKey, setActiveStepKey] = useState(steps[0].key);

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  useEffect(() => {
    assert(
      steps.length,
      "invalid number of steps in AlignWizard progress bar ",
    );

    steps[0].allowNext = () => {
      return !!elementToAlignId && !!referenceElementId;
    };
  }, [elementToAlignId, referenceElementId]);

  const handleNextStep = useCallback(
    (step: FaroStep) => {
      goToNextStep();
      setActiveStepKey(step.key);
    },
    [goToNextStep],
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        mt: -2,
        ml: -2,
        mr: -2,
        p: 1,
        backgroundColor: neutral[100],
        zIndex: 1,
      }}
    >
      <FaroStepper
        steps={steps}
        activeStepKey={activeStepKey}
        onStepChange={handleNextStep}
        lastStepButtonText="Align"
        hideStepNumbers={true}
      />
    </Stack>
  );
}
