import { selectCadLevels } from "@/store/cad/cad-slice";
import { selectSheetElevation } from "@/store/modes/sheet-to-cad-alignment-mode-selectors";
import {
  setSheetElevation,
  setSheetForCadAlignment,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  Dropdown,
  Option,
  TextField,
  TruncatedText,
  neutral,
} from "@faro-lotv/flat-ui";
import {
  IElementSection,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGroup,
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElementWorldTransform,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  selectAllImgSheetsInTimeSeriesDataSession,
  useSheetSelectedForAlignment,
} from "../mode-data-context";

/**
 * @returns All the sheets in the section area in an Dropdown's Option array
 * @param sectionArea The section area containing the sheets to add as an option
 */
function selectSheetOptions(sectionArea: IElementSection) {
  return (state: RootState): Option[] => {
    // Get the group area from the section area
    const groupArea = useAppSelector(
      selectChildDepthFirst(
        sectionArea,
        (el) => isIElementGroup(el) && el.typeHint === IElementTypeHint.area,
      ),
    );

    const sectionAreaSheet = useAppSelector(
      selectChildDepthFirst(groupArea, isIElementGenericImgSheet),
    );

    if (!sectionAreaSheet) {
      throw new Error("Area Section sheet not found in the Group Area");
    }

    const sheetOptions: Option[] = [
      {
        // The key and the label of the dropdown entry should display the the name of the section area
        // as its the name visible to user in the UI
        key: sectionArea.name,
        value: sectionAreaSheet.id,
        label: sectionArea.name,
      },
    ];

    // Add the sheets in the time series data session
    const timeSeriesDataSession = useAppSelector(
      selectChildDepthFirst(sectionArea, isIElementTimeseriesDataSession),
    );
    const sheetsInTimeSeriesDataSession = useAppSelector(
      selectAllImgSheetsInTimeSeriesDataSession(timeSeriesDataSession),
    );

    for (const currentSheet of sheetsInTimeSeriesDataSession) {
      const sheetSectionDataSessionParent = selectAncestor(
        currentSheet,
        isIElementSectionDataSession,
      )(state);
      if (!sheetSectionDataSessionParent) {
        throw new Error(
          "Section DataSession not found in ancestor of the sheet",
        );
      }
      sheetOptions.push({
        // Like for the sheets outside the time series data session, we are using here the name of a parent section as key and label
        key: sheetSectionDataSessionParent.name,
        value: currentSheet.id,
        label: sheetSectionDataSessionParent.name,
      });
    }
    return sheetOptions;
  };
}

/**
 * @returns cad alignment set elevation panel
 */
export function SheetToCadAlignmentSetElevationPanel(): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const sheet = useSheetSelectedForAlignment("sheetToCad");

  const sectionArea = useAppSelector(
    selectAncestor(sheet, isIElementAreaSection),
  );

  if (!sectionArea) {
    throw new Error("Section Area not found for selected sheet.");
  }

  const sheetOptions = useAppSelector(selectSheetOptions(sectionArea));
  const cadLevelsDescription = useAppSelector(selectCadLevels);
  const cadLevelsOptions: Option[] | undefined = cadLevelsDescription?.map(
    (level) => ({
      key: level.Name,
      value: level.Elevation.toString(),
      label: level.Name,
    }),
  );

  const sheetElevation = useAppSelector(selectSheetElevation);

  // Define the state variables for the levels dropdown
  const [levelsDropdownSelection, setLevelsDropdownSelection] = useState("");

  // Define the state variables for the text field
  const [elevationErrorText, setElevationErrorText] = useState("");
  const [elevationTextField, setElevationTextField] = useState(
    sheetElevation ? sheetElevation.toFixed(3).toString() : "N/A",
  );

  // variable to keep track of whether the level dropdowns has been changed by the user
  const levelsDropdownChanged = useRef(false);

  // variable to keep track of whether the text field has been changed by the user
  const elevationTextFieldChanged = useRef(false);

  // Update the UI when the sheet elevation changes
  useEffect(() => {
    if (sheetElevation !== undefined) {
      if (!levelsDropdownChanged.current) {
        setLevelsDropdownSelection("");
      }

      if (!elevationTextFieldChanged.current) {
        setElevationTextField(sheetElevation.toFixed(3));
        setElevationErrorText("");
      }

      levelsDropdownChanged.current = false;
      elevationTextFieldChanged.current = false;
    }
  }, [sheetElevation]);

  return (
    <Stack
      direction="column"
      spacing={3}
      sx={{
        borderLeft: "40px",
        borderLeftColor: neutral[50],
        borderLeftStyle: "solid",
        borderRight: "1px",
        borderRightColor: neutral[200],
        borderRightStyle: "solid",
        pl: 1,
        pr: 1,
        backgroundColor: neutral[0],
        position: "absolute",
        top: 45,
        bottom: 0,
        left: 0,
        width: 350,
      }}
    >
      <TruncatedText
        sx={{
          pt: "15px",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
        Set Elevation
      </TruncatedText>

      <Dropdown
        label="Sheet"
        options={sheetOptions}
        disabled={sheetOptions.length === 1}
        sx={{ marginBottom: "25px", height: "35px" }}
        value={sheet.id}
        onChange={(event) => {
          const sheetId = event.target.value;
          dispatch(setSheetForCadAlignment(sheetId));
          const initialSheetElevation = selectIElementWorldTransform(sheetId)(
            store.getState(),
          ).position[1];
          dispatch(setSheetElevation(initialSheetElevation));
        }}
      />

      <Dropdown
        label="Level"
        options={
          cadLevelsOptions ?? [{ key: "N/A", value: "N/A", label: "N/A" }]
        }
        disabled={!cadLevelsOptions}
        sx={{ marginBottom: "25px", height: "35px" }}
        value={cadLevelsOptions?.length ? levelsDropdownSelection : "N/A"}
        onChange={(event) => {
          levelsDropdownChanged.current = true;
          const newHeight = parseFloat(event.target.value);
          setLevelsDropdownSelection(event.target.value);
          dispatch(setSheetElevation(newHeight));
        }}
      />

      <TextField
        fullWidth={true}
        label="Elevation (m)"
        sx={{ height: "35px" }}
        text={elevationTextField}
        error={elevationErrorText}
        onTextChanged={(newText) => {
          elevationTextFieldChanged.current = true;
          setElevationTextField(newText);
          const newElevation = Number(newText);
          if (isNaN(newElevation)) {
            setElevationErrorText("Elevation must be a number");
            elevationTextFieldChanged.current = false;
            dispatch(setSheetElevation(undefined));
          } else {
            setElevationErrorText("");
            if (sheetElevation !== newElevation) {
              dispatch(setSheetElevation(newElevation));
            }
          }
        }}
      />
    </Stack>
  );
}
